.apr-tag {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding: 2px 4px;
  border-radius: 4px;
  display: inline-block;
  margin-left: 8px;
}

.type-fixed {
  color: #f648b8;
  background-color: #fddbf1;
}

.type-dynamic {
  color: #9363f8;
  background: #eae0fe;
}

@import '../../styles/variable';

.modal-info {
  &__address {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;
    margin-bottom: 32px;
    border: 2px solid #00d261;
    border-radius: 10px;

    &__details {
      display: flex;
      flex-direction: column;

      div:nth-child(1) {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
      }

      div:nth-child(2) {
        display: flex;
        align-items: center;
        gap: 6px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #808080;
      }
    }
  }

  &__clickable {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: flex-end;

    .btn-wrapper {
      display: flex;
      justify-content: center;
      gap: 8px;
      width: 100%;
      flex: 1;
      align-items: center;

      .btn-disconnect {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 45px;
        border: 2px solid #c9c9c9;
        border-radius: 30px;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        background: transparent;
        outline: none;
        &:hover {
          opacity: 0.8;
        }

        &:focus {
          border-width: 3px;
        }
      }
    }

    .explorer {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 6px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #5833eb;
      &:hover {
        font-size: 15px;
      }
      &:focus {
        font-size: 15px;
      }
    }
  }

  &__action {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    gap: 60px;
    color: $blue_primary;

    div {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}

.modal-connect {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  &__loading {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1c2237;
  }

  &__error {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ff0000;
  }

  &__item {
    cursor: pointer;
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #1c2237;
    background: $bg_white_secondary;
    border: 1px solid #b1b5c3;
    border-radius: 10px;
    outline: none;
    box-sizing: border-box;
    height: 78px;
    &:hover {
      border: 2px solid $blue_primary;
    }

    &:focus {
      border: 2px solid $blue_primary;
    }

    img {
      width: 36px;
      height: 36px;
    }
  }
}

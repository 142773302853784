.filter-bar-wrapper {
  background-color: #fcfcfd;
  margin-bottom: 4px;

  .filter-bar {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px;

    .heading {
      font-weight: 700;
      font-size: 26px;
      line-height: 36px;
      color: #23262f;
    }

    .input-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: flex-end;

      .input-search-wrapper {
        position: relative;
        display: flex;
        align-items: center;

        input {
          min-width: 260px;
          border: 1px solid rgba(28, 34, 55, 0.2);
          border-radius: 40px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #1c2237;
          padding: 12px 16px;
          box-sizing: border-box;
          &::placeholder {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #1c2237;
            opacity: 0.3;
          }
          &:focus {
            outline: 1px solid rgba(28, 34, 55, 0.2);
          }
        }

        .icon-search {
          position: absolute;
          right: 12px;
        }
      }

      .trigger-checkbox {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;
        width: 156px;
        background: rgba(28, 34, 55, 0.1);
        border-radius: 40px;
        color: #1c2237;
        cursor: pointer;
        outline: none;
        border: none;
        &:hover {
          opacity: 0.8;
        }

        &:focus {
          opacity: 0.8;
        }

        div {
          margin-right: 40px;
        }
      }
    }
  }
}

.dropdown-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 33px 100px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1c2237;
  .custom-checkbox {
    height: 44px;
    display: flex;
    justify-content: start;
    padding: 0 16px;
    align-items: center;
    &:focus {
      background-color: #fef6fc;
    }
    &:hover {
      background-color: #fef6fc;
    }
  }
}

@media screen and (max-width: 576px) {
  .filter-bar-wrapper {
    .filter-bar {
      padding: 16px;
      width: 100%;
      display: block;

      .input-wrapper {
        justify-content: space-between;

        .btn-search {
          order: 2;
          padding: 8px 36px;
          border: 2px solid #e6e8ec;
          border-radius: 40px;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }

        .input-search-wrapper {
          order: 2;
          width: 100%;

          input {
            width: 100%;
            border: 1px solid rgba(28, 34, 55, 0.2);
            border-radius: 40px;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #1c2237;
            padding: 12px 16px;
            padding-left: 52px;
          }

          .icon-search {
            position: absolute;
            left: 16px;
          }

          .icon-clear {
            position: absolute;
            right: 16px;
            cursor: pointer;
          }
        }

        .trigger-checkbox {
          order: 1;
          padding: 8px 12px;
          width: 120px;
          div {
            margin-right: 12px;
          }
        }
      }
    }
  }

  .dropdown-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0px 33px 100px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    gap: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1c2237;
  }
}

@import '../../../styles/_variable.scss';

.site-layout-background {
  //color
  background-color: $bg_white_secondary;
  border: 1px solid $bd_line;
  border-radius: 16px;
  padding: 20px;
  margin-top: 10px;

  @media screen and (max-width: 768px) {
    padding: 16px;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;

    .ant-switch {
      min-width: unset;
      &:focus {
        transform: scale(1.1);
      }
    }

    .toggle-stake_stake {
      background-color: #00d261;
    }

    .toggle-stake_unstake {
      background-color: #ffb436;
    }

    .pool-tokens.pool-tokens-my-pool {
      width: 100%;

      .logo-wrapper {
        align-self: center;
      }

      .label-switch-wrapper {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: space-between;
        .label-wrapper {
          align-self: center;
          display: flex;
        }
        @media screen and (max-width: 768px) {
          justify-content: flex-end;
          gap: 12px;
          flex-wrap: wrap;
        }
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .pool-tokens {
    width: 100%;
    gap: 4px;

    @media screen and (max-width: 768px) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
    }

    .logo-wrapper {
      display: flex;
      align-items: center;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 3px;
      }

      .logo-info {
        display: flex;
        align-items: center;

        &__icon-token-single {
          width: 33px;
          // height: 33px;
          margin-right: 5px;
          border-radius: 50%;

          @media screen and (max-width: 768px) {
            width: 25px;
            height: 25px;
          }
        }

        &__icon-token-lp {
          position: relative;
          display: flex;
          align-items: center;
          margin-right: 24px;

          &__first {
            width: 36px;
            height: 36px;
            border-radius: 50%;

            @media screen and (max-width: 768px) {
              width: 25px;
              height: 25px;
            }
          }

          &__second {
            display: inline-block;
            position: absolute;
            width: 36px;
            height: 36px;
            left: 18px;
            border-radius: 50%;

            @media screen and (max-width: 768px) {
              width: 25px;
              height: 25px;
            }
          }

          @media screen and (max-width: 768px) {
            height: 25px;
            margin-right: 18px;

            &__first {
              width: 25px;
              height: 25px;
            }

            &__second {
              display: inline-block;
              position: absolute;
              width: 25px;
              height: 25px;
              left: 12px;
            }
          }
        }
      }
    }

    .label-wrapper {
      display: flex;
      align-items: center;
    }

    div.title {
      &.create-date {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #343434;

        height: 30px;
        margin-left: 10px;
        background: #f4f4f4;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 8px 6px;
        gap: 10px;
      }
      &.BSC {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $color_bsc;

        width: 41px;
        height: 30px;
        margin-left: 10px;
        background: rgba(255, 180, 54, 0.2);
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 8px 6px;
        gap: 10px;
      }

      &.KCC {
        width: 27px;
        height: 16px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $color_kcc;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 8px 6px;
        gap: 10px;
        width: 43px;
        height: 30px;
        background: rgba(50, 100, 255, 0.2);
        border-radius: 4px;
        margin-left: 10px;
      }
    }
  }

  .pool-claim {
    background-color: $bg_pink_secondary;
    border-radius: 15px;
    padding: 20px;
    margin: 25px 0px 0px 0px;

    .helper-claim {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      background: linear-gradient(131.66deg, #f649b8 8.75%, #8b66ff 100%);

      div {
        border-radius: 20px;
        background-color: $bg_pink_secondary;
        margin: 1px;
        &:focus-within {
          margin: 2px;
        }
        .btn-claim {
          font-weight: 600;
          font-size: 14px;
          -webkit-background-clip: text;
          background-image: linear-gradient(
            131.66deg,
            #f649b8 8.75%,
            #8b66ff 100%
          );
          -webkit-text-fill-color: transparent;
          transition: all ease 0.3s;
          outline: none;
          &:hover,
          &:focus,
          &:visited,
          &:target,
          &:focus-visible,
          &:focus-within,
          &:active {
            border: 1px none !important;
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }

    p {
      margin: 0;
    }

    .text-title {
      display: flex;
      margin-bottom: 5px;
      line-height: 20px;
      text-align: left;
      color: rgba(28, 34, 55, 0.5);
      .logo-img {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 8px;
        border-radius: 50%;

        &__icon-token-lp {
          display: flex;
          align-items: center;
          position: relative;
          height: 20px;
          margin-right: 18px;

          &__first {
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }

          &__second {
            display: inline-block;
            position: absolute;
            width: 20px;
            height: 20px;
            left: 10px;
            border-radius: 50%;
          }
        }
      }
    }

    .text-value {
      text-align: left;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: $color_text;
    }

    .pool-claim-left {
      gap: 80px;
      .period-until {
        position: relative;
      }
      @media screen and (min-width: 768px) {
        .period-until::after {
          content: '';
          position: absolute;
          top: 3.5px;
          left: 100%;
          width: 39px;
          height: 40px;
          border-right: 2px solid #e6e8ec;
        }
      }
    }

    @media screen and (max-width: 768px) {
      background-color: #fff;
      border-radius: unset;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding: unset;
      margin: 12px 0px 0px 0px;
      padding-top: 12px;
    }
  }

  .pool-status {
    margin-left: 12px;
    padding: 8px;
    min-width: 40px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-transform: capitalize;

    &.pool-live {
      margin: 0px 5px 0px 15px;
      color: $color_icon_secondary;
      border: 1px solid $color_icon_secondary;
    }

    &.pool-ended {
      border: 1px solid #858892;
      color: #858892;
    }
  }

  .toggle-stake {
    .stake-mode {
      background-color: #00d261;
    }

    .unstake-mode {
      background-color: #ffb436;
    }
  }

  .ant-space {
    width: 100%;

    .ant-space-item {
      width: 100%;

      .ant-row {
        align-items: center;

        .ant-col {
          .fw-700 {
            text-align: left;

            &.titleText {
              margin-left: 3px;
              margin-right: 16px;
              color: #23262f;
              font-size: 16px;

              @media screen and (max-width: 768px) {
                margin-left: 5px;
              }
            }

            &.titleTextPersonal {
              margin-left: 3px;
              margin-right: 16px;
              color: #23262f;
              font-size: 16px;
            }

            .BSC {
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 16px;
              color: #ffb436;
              width: 41px;
              height: 30px;
              background: rgba(255, 180, 54, 0.2);
              border-radius: 4px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 8px 8px 6px;
              grid-gap: 10px;
              gap: 10px;
            }

            .KCC {
              width: 27px;
              height: 16px;
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 16px;
              color: $color_kcc;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 8px 8px 6px;
              gap: 10px;
              width: 43px;
              height: 30px;
              background: rgba(50, 100, 255, 0.2);
              border-radius: 4px;
            }
          }
        }

        .coinName {
          span:last-child {
            display: none;
          }
        }
      }

      .ant-col {
        display: flex;
        align-items: center;

        &.ant-col-6 {
          &:first-child {
            display: flex;
            align-items: center;

            div {
              margin-left: 12px;
            }
          }
        }
      }
    }
  }

  .Stake {
    // float: right;
    display: flex;
    flex-wrap: wrap;
    width: 148px;
    border-radius: 15px;

    &.Claim {
      width: 100%;
      padding: 20px;

      .fw-400 {
        font-size: 14px;
        color: #002979;
        width: 15%;
      }
    }
  }

  button {
    &.ant-btn {
      // color
      background-color: $blue_primary;
      color: $bg_white_secondary;

      //display
      width: 200px;
      height: 30px !important;
      border-radius: 8px;
      line-height: 100%;

      //mouse events
      &:hover,
      &:focus,
      &:visited,
      &:target,
      &:focus-visible,
      &:focus-within,
      &:active {
        background: $blue_primary;
      }
    }

    &.btn-stake {
      padding: 17px 48px;
      border-radius: 20px;
      font-size: 12px;
      font-weight: 600;
      line-height: 0;
      margin: 0;
      border: none;
      &:focus {
        outline-color: #f516ff;
      }
      @media screen and (max-width: 768px) {
        align-self: flex-start;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        padding: 5px 22px;
        height: unset !important;
        width: unset;
      }

      &.btn-disabled {
        &.ant-btn {
          border: none;
          opacity: 0.5;
          cursor: not-allowed;
          background-color: $gray;
          outline: none;
          &:hover,
          :active {
            background-color: $gray !important;
            border-color: $gray !important;
            color: #ffff !important;
          }
        }
      }
    }

    &.btn-claim {
      // margin-top: 15px;
      padding: 17px 48px;
      border-radius: 20px;
      font-size: 12px;
      font-weight: 600;
      line-height: 0;
      width: 148px;
      color: black;
      background-color: transparent;
      border: none;
      cursor: pointer;

      //mouse events
      &:hover,
      &:focus,
      &:visited,
      &:target,
      &:focus-visible,
      &:focus-within,
      &:active {
        color: #fff;
        background: $blue_primary;
        border-color: $blue_primary;

        &.disable {
          cursor: not-allowed;

          &:hover {
            background-color: $gray !important;
          }
        }
      }

      &.disable {
        opacity: 0.3 !important;
      }

      @media screen and (max-width: 768px) {
        padding: 4px 22px;
        font-size: 12px !important;
        line-height: 16px !important;
        width: unset;
      }
    }
  }

  .Stake-container {
    width: 100%;
    margin-top: 15px;

    @media screen and (max-width: 768px) {
      margin-top: unset;
    }

    .Stake-left {
      width: 100%;

      @media screen and (min-width: 769px) {
        .ant-col:last-child {
          flex-grow: 1;
          display: flex;
          justify-content: flex-end;
        }
      }

      @media screen and (max-width: 1040px) {
        .ant-col-5 {
          max-width: unset;
          min-width: unset;
          flex: 0 0 12%;
        }
      }

      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;

        .ant-col {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          padding: 12px 0;
        }

        .ant-col:last-child {
          padding-bottom: 0;
        }

        .ant-col-5,
        .ant-col-4 {
          flex: unset;
          max-width: unset;
        }
      }

      .ant-col {
        .fw-500 {
          color: rgba(0, 0, 0, 0.5) !important;
        }

        .text-title {
          margin-bottom: 5px;
          line-height: 20px;
          text-align: left;
          color: #1c2237;
        }

        .title {
          text-align: left;
          color: $color_text;
        }

        .APR {
          color: $color_icon_secondary;
        }

        .anticon-info-circle {
          margin-left: 6.17px;
        }
      }
    }

    .reward-tokens {
      display: flex;
      align-items: center;
      gap: 12px;
      flex-wrap: wrap;
      color: #292929;

      &.tokens-3 {
        flex-direction: column;
        align-items: flex-start;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .ant-avatar {
        margin-right: 4px;
      }

      &__icon-token-lp {
        display: flex;
        align-items: center;
        position: relative;
        height: 20px;

        &__first {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }

        &__second {
          display: inline-block;
          position: absolute;
          width: 20px;
          height: 20px;
          left: 10px;
          border-radius: 50%;
        }

        &__symbol {
          margin-bottom: 0;
          margin-left: 18px;
        }
      }
    }

    .my-stake {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      p {
        margin: 0;
        font-size: 14px;
        font-weight: 700;
        color: #242835;
        line-height: 20px;
      }
    }

    .anticon-question-circle {
      margin-left: 15px;
      font-size: 15px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.status-mobile {
  @media screen and (max-width: 768px) {
    margin-left: 0 !important;
  }
}

.my-stake-wrapper {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;

  .my-stake-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    div:nth-child(1) {
      color: rgba(28, 34, 55, 0.5);
    }

    div:nth-child(2) {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #242835;
    }
  }
}

.btn-stake-mob-helper {
  position: relative;

  .token-stake-wrapper {
    width: 100%;
  }

  .btn-stake-wrapper {
    @media screen and (max-width: 768px) {
      position: absolute;
      right: 0;
      top: 12px;
    }
  }
}

.reward-mob-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  margin-top: 12px;
}

.ant-btn:active,
.ant-btn:focus {
  outline: #000 solid 1px;
}

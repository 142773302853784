.btn-linear-gradient {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(131.66deg, #f649b8 8.75%, #8b66ff 100%);
  border-radius: 30px;
  height: 40px;
  padding: 0 16px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border: none;
  gap: 6px;
  width: 100%;
  outline: none;
  &:hover {
    opacity: 0.8;
  }

  &:focus {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.w-full {
  width: 100%;
}

@import '../../../styles/variable';

.header-wrapper {
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 1002;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 26px 40px;
    margin: auto;

    &__logo {
      cursor: pointer;
    }

    &__logo-wrapper {
      display: flex;
      align-items: center;
      gap: 16px;
      .beta-tag {
        height: 32px;
        line-height: 16px;
        font-size: 18px;
        padding: 8px;
        background: rgba(244, 73, 184, 0.1);
        border-radius: 8px;
        color: #f648b8;
      }
    }

    .connected-info {
      display: flex;
      gap: 10px;
      align-items: center;

      &__connected-network {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 52px;
        height: 40px;
        border-radius: 8px;
        padding: 8px 8px 6px;
        font-size: 12px;
        &.BSC {
          border: 1px solid $color_bsc;
          color: $color_bsc;
        }
        &.KCC {
          border: 1px solid $color_kcc;
          color: $color_kcc;
        }
      }

      &__btn-info {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: linear-gradient(131.66deg, #f649b8 8.75%, #8b66ff 100%);
        border-radius: 12px;
        padding: 1px;
        height: 40px;
        box-sizing: border-box;
        &:hover {
          opacity: 0.8;
        }
        &:focus-within {
          padding: 2px;
        }
        .connected {
          padding: 8px 15px !important;
          &:focus-within {
            padding: 7px 15px !important;
          }
          &__btn {
            outline: none;
            background: transparent;
            border: none;
            cursor: pointer;
            font-weight: 600;
            background: linear-gradient(131.66deg, #f649b8 8.75%, #8b66ff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .helper {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          background-color: #ffffff;
          padding: 9px 15px;
          &:focus-within {
            padding: 8px 15px;
          }
          border-radius: 11px;
          &__connect-btn {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            background: linear-gradient(131.66deg, #f649b8 8.75%, #8b66ff 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            outline: none;
            border: none;
            cursor: pointer;
          }
        }
      }
    }
    &__btn-switch {
      background: #eb3333;
      border-radius: 22px;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: $bg_white_secondary;
      padding: 6px 16px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.toast-err {
  display: flex;
  align-items: center;
  gap: 10px;

  .img-wrong-wrapper {
    padding: 8px;
    background: #ff6838;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 576px) {
  .header-wrapper {
    .header {
      padding: 16px 12px;
    }
  }
}

.spinner {
  font-size: 24px;
  width: 1em;
  height: 1em;
  position: relative;
  .loader-wheel__spoke {
    display: block;
    position: absolute;
    width: 4px;
    height: 8px;
    background: #461a57;
    border-radius: 0.05em;
    left: 50%;
    top: 50%;
    transform-origin: left top;
    opacity: 0;
    animation: fade 0.8s linear infinite;
  }

  @keyframes fade {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .loader-wheel__spoke:nth-child(1) {
    transform: rotate(0deg) translate(-50%, 70%);
  }
  .loader-wheel__spoke:nth-child(2) {
    transform: rotate(45deg) translate(-50%, 70%);
  }
  .loader-wheel__spoke:nth-child(3) {
    transform: rotate(90deg) translate(-50%, 70%);
  }
  .loader-wheel__spoke:nth-child(4) {
    transform: rotate(135deg) translate(-50%, 70%);
  }
  .loader-wheel__spoke:nth-child(5) {
    transform: rotate(180deg) translate(-50%, 70%);
  }
  .loader-wheel__spoke:nth-child(6) {
    transform: rotate(225deg) translate(-50%, 70%);
  }
  .loader-wheel__spoke:nth-child(7) {
    transform: rotate(270deg) translate(-50%, 70%);
  }
  .loader-wheel__spoke:nth-child(8) {
    transform: rotate(315deg) translate(-50%, 70%);
  }

  .loader-wheel__spoke:nth-child(2) {
    animation-delay: 0.1s;
  }
  .loader-wheel__spoke:nth-child(3) {
    animation-delay: 0.2s;
  }
  .loader-wheel__spoke:nth-child(4) {
    animation-delay: 0.3s;
  }
  .loader-wheel__spoke:nth-child(5) {
    animation-delay: 0.4s;
  }
  .loader-wheel__spoke:nth-child(6) {
    animation-delay: 0.5s;
  }
  .loader-wheel__spoke:nth-child(7) {
    animation-delay: 0.6s;
  }
  .loader-wheel__spoke:nth-child(8) {
    animation-delay: 0.7s;
  }
}

$blue_primary: #5833eb;
$gray: gray;
$bg_white_primary: #f3f6fd;
$bg_white_secondary: #ffffff;
$bg_white_three: #fcfcfd;
$fw_500: 500;
$fw_600: 600;
$bd_line: rgba(163, 174, 208, 0.3);
$bg_pink_primary: #fef4fb;
$bg_pink_secondary: #fef6fc;
$bg_button: rgba(28, 34, 55, 0.1);
$color_icon: #777e90;
$color_text: #242835;
$color_icon_secondary: #f648b8;
$color_bsc: #ffb436;
$color_kcc: #5833eb;
$color_ended: #858892;

// Colors
// $brand-primary-deep: #235fa4 !default;
// $brand-primary: #387ac6 !default;
// $brand-primary-pale: #ecf3fc !default;
// $brand-primary-pale-deep: #bdd5f1 !default;
// $brand-secondary: #ffb44b !default;
// $brand-secondary-deep: #e7982a !default;
// $brand-secondary-pale: #faf4ec !default;
// $brand-success: #1faab3 !default;
// $brand-success-deep: #1e9ea7 !default;
// $brand-success-pale: #e8f8f9 !default;
// $brand-danger-base: #ef4577 !default;
// $brand-danger-pale: #fcedf1 !default;
// $color-base: #101c2a;
// $white-base: #ffffff !default;
// $black-base: #000000 !default;
// $pale-base: #eaeef2 !default;
// $dark-base: #233040 !default;
// $gray-base: #929bad !default;
// $gray-light: #cccccc !default;
// $gray-lightest: #f5f7fa !default;
// $gray-lighter: #dcdfe6 !default;
// $text-color-base: $color-base !default;
// $bg-base: $white-base !default;
// $red-base: #dd1b1b !default;
// $red-base-deep: #bb1616 !default;
// $bg-loading-bar: #0984e3 !default;
// $bg-chat: #f0f0f0 !default;
// $border-gray-opacity: rgba(146, 155, 173, 0.4) !default;

// Fonts
$font-size-tiny: 12px !default;
$font-size-small: 14px !default;
$font-size-medium: 15px !default;
$font-size-base: 16px !default;
$font-size-large: 24px !default;
$font-size-x-large: 32px !default;
$font-family-base: 'Roboto', sans-serif !default;

// Typography
$text-color-gray-light: #93a2b3 !default;

// Margin, padding and spacing
$line-height-base: 1.428571429 !default;
$line-height-large: 1.3333333 !default;
$line-height-small: 1.5 !default;
$line-height-computed: floor(($font-size-base * $line-height-base)) !default;
$block-padding-base: 10px 20px !default;
$padding-margin-small: 12px !default;
$padding-margin-base: 16px !default;
$padding-margin-medium: 20px !default;
$padding-large-base: 32px !default;

// Border radius
$border-radius-small: 3px !default;
$border-radius-medium: 5px !default;
$border-radius-base: 8px !default;
$border-radius-large: 12px !default;
$border-radius-circle: 50px !default;
$circle-border-radius: 50% !default;

// Opacity
$opacity-disabled: 0.6;

// Animation timing
$animation-time-fast: 0.3s !default;
$animation-time-normal: 0.5s !default;
$animation-time-slow-base: 0.8s !default;
$animation-time-slow-medium: 1s !default;

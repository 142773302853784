.modal-stake {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__notice {
    background: #eeebfd;
    border-radius: 8px;
    padding: 16px 20px;
    display: flex;
    gap: 14px;
    flex-direction: column;
    color: #292929;
  }

  &__input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .error {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #f02727;
    }
  }

  &__btn-group {
    display: flex;
    align-items: center;
    gap: 8px;

    .btn-cancel {
      width: 100%;
      border: 2px solid #c9c9c9;
      border-radius: 30px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      height: 45px;
      color: #000000;
      background-color: transparent;
      cursor: pointer;
      outline: none;
      &:focus {
        border-width: 3px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .token-info {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    .token {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      font-weight: 400;

      div:first-child {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #292929;
      }
    }
  }

  .text-token {
    font-size: 16px;
  }
}

.toast-mess-approve-failed {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  background: #ffe6de;
  color: #353945;
  border-radius: 8px;
  border: 1px solid #f02727;
  padding: 4px 32px 4px 4px;
}

.toast-mess-approve-success {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  background: #e8fff0;
  color: #353945;
  border-radius: 8px;
  border: 1px solid #00d261;
  padding: 4px 32px 4px 4px;

  .description {
    display: flex;
    align-items: center;
    gap: 12px;

    .link {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-decoration-line: underline;
      color: #777e90;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}

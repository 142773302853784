.modal-unstake {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__notice {
    background: #eeebfd;
    border-radius: 8px;
    padding: 16px 20px;
    display: flex;
    gap: 14px;
    flex-direction: column;
    color: #292929;
  }

  &__input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .error {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #f02727;
    }
  }

  &__btn-group {
    display: flex;
    align-items: center;
    gap: 8px;

    .btn-cancel {
      width: 100%;
      border: 2px solid #c9c9c9;
      border-radius: 30px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      height: 45px;
      color: #000000;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .token-info {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    .token {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      font-weight: 400;

      div:first-child {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #292929;
      }
    }
  }

  .text-token {
    font-size: 16px;
  }
}

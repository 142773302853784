#root {
  height: 100%;
  overflow-y: auto;
}

body {
  font-family: 'Poppins', sans-serif;
}

body > iframe {
  pointer-events: none;
}

body > iframe {
  pointer-events: none;
}

.main {
  margin: auto;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 92px;

  @media (max-width: 576px) {
    top: 72px;
  }

  .content-wrapper {
    width: 100%;
    background-color: #fef4fb;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .content {
      padding-top: 4px;
      flex-grow: 1;
    }
  }
}

.modal-progress {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding-top: 44px;

  .progress {
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: #000000;
  }

  .action {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    opacity: 0.5;
  }

  .link {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #5833eb;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  .dismiss {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    padding: 12px 0;
    width: 100%;
    border: 2px solid #c9c9c9;
    border-radius: 30px;
  }
}

@import '../../styles/variable';

.modal-switch {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;

  &__item {
    width: 100%;
    padding: 16px 20px;
    border: 1px solid #b1b5c3;
    border-radius: 10px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #1c2237;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
      border: 2px solid #5833eb;
    }
  }
}

// Display
.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-align-center {
  align-items: center;
}

.flex-align-end {
  align-items: end;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: end;
}

.justify-around {
  justify-content: space-around;
}

// Typography
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.text-disabled {
  color: #242835;
  opacity: 0.5;
}

.slowlyScaleBottom {
  animation-name: slowlyScale;
  animation-duration: 0.3s;
  transform-origin: bottom;
}

.slowlyScale {
  animation-name: slowlyScale;
  animation-duration: 0.3s;
  transform-origin: top;
}

@keyframes slowlyScale {
  from {
    opacity: 0;
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
    opacity: 1;
  }
}

.modal-claim {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  margin-bottom: 30px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #292929;

  div:first-child {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #292929;
  }

  .text-claim {
    font-weight: 400 !important;
    font-size: 16px;
  }
}

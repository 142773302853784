.input-staking-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;

  .token-info-with-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;

    .name {
      display: flex;
      align-items: center;
      gap: 4px;

      img {
        width: 20px;
        border-radius: 50%;
      }

      div {
        opacity: 0.5;
      }

      &__icon-token-lp {
        position: relative;
        height: 20px;
        display: flex;
        align-items: center;
        margin-right: 16px;
        opacity: 1 !important;

        &__first {
          width: 20px;
          height: 20px;
        }

        &__second {
          display: inline-block;
          position: absolute;
          width: 20px;
          height: 20px;
          left: 10px;
        }
      }
    }

    .amount {
      opacity: 0.5;
    }
  }

  .input-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    input {
      width: 100%;
      background: #ffffff;
      border: 2px solid rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      padding: 13px 14px;
      padding-right: 80px;
      outline: none;
      height: 50px;
      box-sizing: border-box;
      &::placeholder {
        opacity: 0.3;
      }
      &:focus {
        border-width: 3px;
      }
      &:disabled {
        cursor: not-allowed;
      }
    }

    button {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #5833eb;
      cursor: pointer;
      padding: 4px 16px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      position: absolute;
      right: 14px;
      background-color: transparent;
      outline: none;

      &:hover {
        opacity: 0.8;
      }

      &:focus {
        border-width: 2px;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  .mess-err {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #f02727;
  }
}

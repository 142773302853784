.custom-checkbox {
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  & img {
    margin-right: 8px;
  }
}

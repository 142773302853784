@import '../../../styles/variable';

.footer-wrapper {
  width: 100%;
  .footer {
    height: 84px;
    background-color: #1c2336;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 36px;
    .logo-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;
      .beta {
        border-radius: 6px;
        background-color: rgba(244, 73, 184, 0.2);
        padding: 4px 6px;
        font-size: 12px;
        color: $color_icon_secondary;
      }
    }
    &__powered-by {
      color: #ffffff;
    }
  }
}

@import '../../styles/_variable.scss';

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

body {
  background-color: $bg_pink_primary;
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;

  .containerTab {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    background: $bg_white_secondary;

    .tab-content {
      background-color: #fff;

      .disconnect-frame,
      .no-record {
        position: relative;
        height: calc(100vh - 340px);

        > div {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        p {
          margin-top: 16px;
        }
      }
    }

    .search {
      background: $bg_white_three;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;

      h1 {
        background: $bg_white_three;
        margin: 0;
        padding: 30px;
      }
    }

    div {
      .ant-tabs-content-holder {
        max-width: 1400px;
        margin: 0 auto;
        width: 100%;
        padding: 20px;
        @media screen and (max-width: 768px) {
          padding: 16px;
        }
        .ant-tabs-content {
          .ant-tabs-tabpane {
            &:focus {
              outline: none;
            }
            .ant-layout-content {
              margin: 0 0 30px 0 !important;
            }

            div {
              ul {
                &.ant-pagination {
                  display: flex;
                  justify-content: flex-end;
                  margin-top: 46px;
                  margin-bottom: 24px;
                  padding-right: 32px;
                  color: #1c2237;
                  cursor: pointer;

                  @media screen and (max-width: 576px) {
                    justify-content: center;
                    margin-top: 24px;
                    padding-right: unset;
                  }
                }
                & .ant-pagination-disabled {
                  button {
                    background: $bg_button !important;
                  }
                }
                li {
                  button {
                    &.ant-pagination-item-link {
                      background: bg_white_secondary;
                      color: $color_icon;
                    }
                  }

                  &.ant-pagination-item-active {
                    border: 1px solid $color_icon_secondary !important;
                    a {
                      color: $color_icon_secondary !important;
                      &:hover {
                        color: $color_icon_secondary;
                      }
                      &:focus-visible {
                        color: $color_icon_secondary;
                      }
                    }
                  }

                  &.ant-pagination-item {
                    a {
                      color: #1c2237;
                      font-style: normal;
                      font-weight: 600;
                      font-size: 14px;
                      font-family: 'Poppins';
                    }
                  }
                }
              }
            }

            .rewardinfor {
              .ant-col {
                .anticon {
                  margin-left: 20px;
                  font-size: 20px;
                }
              }
            }
          }
        }

        .ant-space-item {
          .ant-row {
            justify-content: space-between;
            .ant-col-2 {
              .anticon-down {
                justify-content: end;
              }
            }
          }
        }
      }
    }

    .ant-tabs-nav {
      margin-bottom: 0;
      padding: 16px 20px;
      border-bottom: 4px solid $bg_pink_primary;
      background-color: #fcfcfd;

      @media screen and (max-width: 576px) {
        padding: 16px;
      }

      &::before {
        border: none;
      }

      .ant-tabs-nav-wrap {
        @media screen and (max-width: 576px) {
          display: flex;
          justify-content: center;
        }

        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
        padding: 16px 20px;

        .ant-tabs-nav-list {
          display: flex;
          justify-content: space-around;
          padding: 4px;
          height: 50px;
          border-radius: 50px;
          background: #f7f7f7;

          .ant-tabs-tab {
            // display
            display: flex;
            justify-content: center;
            padding: 12px 50px;
            @media screen and (max-width: 576px) {
              padding: 12px 40px;
            }
            //color
            color: rgba(0, 0, 0, 0.3);
            margin: 0;
            //font-type:
            font-weight: $fw_600;
            width: 100%;
            transition: 0.5s;

            &.ant-tabs-tab-active {
              background: #ffffff;
              border-radius: 50px;
              box-shadow: 0px 4px 40px rgba(167, 169, 183, 0.15);

              .ant-tabs-tab-btn {
                //color
                color: $blue_primary;
                //font-type
                font-weight: $fw_600;
                width: 100%;
                text-align: center;
              }
            }
          }

          .ant-tabs-ink-bar {
            //display
            height: 4px;
            display: none;
            //color
            background: $blue_primary;
          }
        }
      }
    }
  }
}

.pool-tooltip {
  margin-left: 10px;
  .ant-tooltip-inner {
    padding: 8px 20px;
    max-width: 240px;
    border-radius: 5px;
    box-shadow: 0px 7px 30px rgba(84, 84, 84, 0.35);
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #000;

    @media screen and (max-width: 768px) {
      max-width: 160px;
    }
  }
}
